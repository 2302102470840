import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroLayout } from "../components/hero-layout";
import { getQueryParam } from "../utils/query";

export const UnsubscribePage = ({ location }) => {
  const [ohandle, setOhandle] = React.useState(null);
  React.useEffect(() => {
    setOhandle(getQueryParam("ohandle", location));
  }, []);
  const url = `https://${ohandle}.remeet.com/?dialog=preferences`;

  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Unsubscribe"} />
      <HeroLayout>
        <div className="text-content-container">
          <h1 className="mdc-typography--headline4">
            You have unsubscribed from all Remeet app email notifications.
          </h1>
          <p>
            You can re-subscribe in your{" "}
            <a href={url}>notification preferences</a>
          </p>
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default UnsubscribePage;
